<template>
  <div class="content-page">
    <div class="content-nav" style="height: 80px">
      <div style="display: flex">
        <img
          src="/static/images/logo.png"
          alt="Smiley face"
          width="60"
          height="60"
          style="margin-left: 6px"
        />
        <div>
          <div style="margin-left: 10px; display: flex">
            <div style="margin-top: 5px; font-size: 20.76px; color: black">
              安徽万玻供应链科技有限公司
            </div>
            <div style="margin-top: 11px; margin-left: 20px">
              欢迎您，记得每天都来看看会有新发现
            </div>
          </div>
          <div style="margin-top: 4px; margin-left: 10px">
            <!-- <div
              style="
                background: linear-gradient(
                  180deg,
                  #0086edff 0%,
                  #00c4ffff 100%
                );
                border: 2px solid #ffffffff;
              "
            ></div> -->
            <div class="vip">万玻认证会员 到期时间：2022-08-24</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-main clearfix">
      <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="昨日" name="second"></el-tab-pane>
        <el-tab-pane label="今日" name="third"></el-tab-pane>
        <el-tab-pane label="近15天" name="fourth"></el-tab-pane>
        <el-tab-pane label="近30天" name="fivth"></el-tab-pane>
      </el-tabs> -->

      <div class="header clearfix">
        <div class="anysis-view">
          <div style="width: 64%">
            <div class="data-wrap">
              <div class="data-view">
                <div class="data-card">
                  <div class="data-number">{{infoData.viewsNum}}次</div>
                  <div class="data-title">总曝光量</div>
                  <div class="data-desc">展示给用户的次数</div>
                </div>
                <div class="data-card">
                  <div class="data-number">{{infoData.clickNum}}次</div>
                  <div class="data-title">总点击量</div>
                  <div class="data-desc">用户点击进入您企业详情的次数</div>
                </div>
                <div class="data-card">
                  <div class="data-number">{{infoData.userNumber}}人</div>
                  <div class="data-title">总访问人数</div>
                  <div class="data-desc">用户浏览您企业详情的人数</div>
                </div>
                <div class="data-card">
                  <div class="data-number">{{infoData.timeNum}}</div>
                  <div class="data-title">总平均浏览</div>
                  <div class="data-desc">用户浏览企业详情停留的时间</div>
                </div>
                <div class="data-card">
                  <div class="data-number">{{infoData.intendedUser}}人</div>
                  <div class="data-title">总意向人数</div>
                  <div class="data-desc">
                    点击“添加微信”和“电话联系”的用户人数
                  </div>
                </div>
              </div>
            </div>
            <div class="ad-view">
              <img
              @click="goAdQa"
                src="/static/images/kaitong.png"
                alt=""
                style="width: 100%; margin-top: 10px"
              />
            </div>
          </div>
          <div id="main" style="width: 36%; height: 210px"></div>
        </div>
      </div>
    </div>

    <div class="qs-main">
      <div style="margin: 10px; padding: 10px">疑问解答</div>
      <div class="qs-list">
        <el-button
          type="primary"
          plain
         @click="goQAWeb"
          style="margin: 10px; background-color: white;color:#409EFF"
          >怎么获取更多商机？</el-button
        >
        <el-button
          type="primary"
          plain
          @click="goQAWeb"
          style="margin: 10px; background-color: white;color:#409EFF"
          >怎么增加曝光量？</el-button
        >
        <el-button
          type="primary"
          plain
          @click="goQAWeb"
          style="margin: 10px; background-color: white;color:#409EFF"
          >怎么增加点击量？</el-button
        >
        <el-button
          type="primary"
          plain
          @click="goQAWeb"
          style="margin: 10px; background-color: white;color:#409EFF"
          >怎么提高意向人数？</el-button
        >
      </div>
    </div>

    <div class="content-main clearfix" style="margin-top: 10px">
      <el-tabs v-model="activeList" type="card" @tab-click="handleClick">
        <el-tab-pane label="强意向客户" name="first"></el-tab-pane>
        <el-tab-pane label="访问记录" name="second"></el-tab-pane>
        <el-tab-pane label="线上订单管理" name="third"></el-tab-pane>
      </el-tabs>

      <div class="content-main">
        <div class="form-table-box" v-if="activeList == 'first'">
        <div  style="color:#CD3F31FF;margin-top:-28px;margin-bottom:10px">强意向客户：指客户看过您的企业详情，并且点击了【电话联系】或【微信沟通】按钮</div>
          <el-table :data="tableData" style="width: 100%;" border stripe>
            <el-table-column prop="timeText" label="时间" width="100">
            </el-table-column>
            <el-table-column label="头像" width="80">
              <template scope="scope">
                <img
                  :src="scope.row.avatar"
                  v-if="scope.row.avatar"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"> </el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
            <el-table-column prop="type" label="动态" width="110">
              <template scope="scope">
                <el-button
                  v-if="scope.row.type == 'footprint'"
                  type="primary"
                  size="small"
                  >浏览产品</el-button
                >
                <el-button
                  v-if="scope.row.type == '1'"
                  type="success"
                  size="small"
                  >查看主页</el-button
                >
                 <el-button
                  v-if="scope.row.type == '2'"
                  type="danger"
                  size="small"
                  >拨打电话</el-button
                >
                 <el-button
                  v-if="scope.row.type == '3'"
                  type="warning"
                  size="small"
                  >查看微信</el-button
                >
              </template>
            </el-table-column>

          
            <!-- <el-table-column label="操作">
              <template scope="scope">
                <el-button
                  size="small"
                  type="primary"
                  plain
                  @click="unLockMsg(scope.$index, scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <div class="form-table-box" v-if="activeList == 'second'">
          <el-table :data="tableData" style="width: 100%;margin-top:-28px;margin-bottom:10px" border stripe>
            <el-table-column prop="timeText" label="时间" width="100">
            </el-table-column>
            <el-table-column label="头像" width="80">
              <template scope="scope">
                <img
                  :src="scope.row.avatar"
                  v-if="scope.row.avatar"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"> </el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
            <el-table-column prop="type" label="动态" width="110">
              <template scope="scope">
                <el-button
                  v-if="scope.row.type == 'footprint'"
                  type="primary"
                  size="small"
                  >浏览产品</el-button
                >
                <el-button
                  v-if="scope.row.type == '1'"
                  type="success"
                  size="small"
                  >查看主页</el-button
                >
                 <el-button
                  v-if="scope.row.type == '2'"
                  type="danger"
                  size="small"
                  >拨打电话</el-button
                >
                 <el-button
                  v-if="scope.row.type == '3'"
                  type="warning"
                  size="small"
                  >查看微信</el-button
                >
              </template>
            </el-table-column>

            <!-- <el-table-column prop="keyword" label="搜索关键词">
              <template scope="scope">
                <el-tag type="success" v-if="scope.row.keyword">{{
                  scope.row.keyword
                }}</el-tag>
              </template>
            </el-table-column> -->
<el-table-column prop="duration" label="停留时长(s)">
            </el-table-column>

            <el-table-column label="商品" width="80">
              <template scope="scope">
                <img
                  :src="scope.row.list_pic_url + '=thumb'"
                  v-if="scope.row.list_pic_url"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="goods_name" label="商品名称">
            </el-table-column>

            
            <!-- <el-table-column label="操作">
              <template scope="scope">
                <el-button
                  size="small"
                  type="primary"
                  plain
                  @click="unLockMsg(scope.$index, scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <div class="page-box">
          <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Notify from '@wcjiang/notify';
import echarts from "echarts";

export default {
  name: "welcome",
  data() {
    return {
      charts: "",
      opinionData: ["3", "2", "4", "4", "5"],
      page: "",
      total: 0,
      tableData: [],

      activeList: "first",
      activeName: "first",
      infoData: {
        viewsNum: "",
        clickNum: "",
        newsViews: "",
        recuirtViews: "",
        userNumber:'',
        timeNum:'',
        infoData:''
      },
    };
  },
  methods: {
    handleClick() {
      if(this.activeList == 'third'){
          this.$router.push({ name: "order" });
          return false;
      }
      this.page= 1;
      this.getList();
    },
    getMainInfo() {
      this.axios.get("remind/statistics").then((response) => {
        this.infoData = response.data.data;
      });
    },
    //
    goQAWeb(){
      window.open('https://shimo.im/docs/XKq4MVleBxCX59kN/');
    },
      goAdQa(){
        window.open('https://shimo.im/docs/gXqmeXdB2NTJa4qo/');
      },
    getList() {
      this.axios
        .get("dynamic/dynamicList", {
          params: {
            page: this.page,
            type:this.activeList == 'first'?'1':'2'
          },
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = parseInt(response.data.data.currentPage);
          this.total = response.data.data.count;
        });
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));
      let option = {
        xAxis: {
          data: ["A", "B", "C", "D", "E"],
        },
        yAxis: {},
        series: [
          {
            data: [10, 22, 28, 43, 49],
            type: "line",
            stack: "x",
            smooth: true,
          },
          {
            data: [5, 4, 3, 5, 12],
            type: "line",
            stack: "x",
            smooth: true,
          },
        ],
      };
      this.charts.setOption(option);
    },
  },

  components: {},
  mounted() {
    this.getMainInfo();
    let thePage = 1;
    this.page = Number(thePage);
    this.getList();
    // this.$nextTick(() => {
    //   this.drawLine("main");
    // });
  },
};
</script>

<style scoped>
.vip {
  opacity: 1;
  background: #e0f8ffff;
  color: rgba(42, 130, 228, 1);
}
.anysis-view {
  display: flex;
}
.data-view {
  display: flex;
  /* width: 64%; */
}
.data-card {
  padding: 10px;
  margin: 10px;
  width: 200px;
}
.data-number {
  font-size: 20.76px;
  font-weight: 500;

  color: rgba(0, 161, 29, 1);
  text-align: center;
  white-space: nowrap;
}
.data-title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-top: 5px;
  white-space: nowrap;
}
.data-desc {
  font-size: 9.82px;
  font-weight: 400;
  text-align: center;
  color: rgba(105, 105, 105, 1);
}

.qs-main {
  background-color: white;
  display: flex;
  margin-top: 10px;
}
.notice {
  width: 100%;
  height: 60px;
  padding: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffecd5;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notice .r {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.github a {
  margin-right: 20px;
}
.count {
  background: #fff;
  padding: 10px;
}
.float-right {
  float: right;
}
.tips {
  color: #8c939d;
  font-size: 13px;
}

.text {
  font-size: 14px;
}

.item {
  padding: 10px 0;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.tab-content {
  margin-bottom: 20px;
}
.box-card {
  width: 32%;
  float: left;
  margin: 0 20px 14px 0;
}

.box-card:last-child {
  margin-right: 0px;
}

.box-card .link-color {
  color: #fff;
}

.box-card:last-child {
  margin-right: 0;
}

.box-card2 {
  width: 32%;
  float: left;
  margin-right: 17px;
}

.box-card2:last-child {
  margin-right: 0;
}

.header {
  /* margin-bottom: 30px; */
}

.line {
  margin: 20px 0;
  border-top: 1px solid #d1dbe5;
}

.card-red {
  background: #e64242;
  border: none;
  width: 30%;
  color: #fff;
}

.card-blue {
  background: #4db3ff;
  border: none;
  width: 30%;
  color: #fff;
}
.card-green {
  background: #11b95c;
  border: none;
  width: 30%;
  color: #fff;
}
.card-black {
  background: #1f2d3d;
  border: none;
  width: 30%;
  color: #fff;
}
.card-gray {
  background: #d1dbe5;
  width: 30%;
  border: none;
}
.card-gray a {
  color: #1f2d3d;
  width: 30%;
}
.card-yellow {
  background: #f8dd66;
  border: none;
  color: #111111;
  width: 30%;
}

.card-yellow .link-color {
  color: #111111;
}
</style>
